@import url('https://fonts.googleapis.com/css2?family=Unna:wght@700&display=swap');
body{
  font-family: 'Unna', serif;
  /* background-color: #000; */
}

*{
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
img{
  width: 100%;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
p {
  margin-bottom: 0.2rem;
  font-size: 17px;
  /* color: #cc4947!important; */
}
.menu-link-right{
  justify-content: end;
}
.navbar-nav a{
  color: #fff;
    text-decoration: none;
    font-size: 19px;
    padding: 5px 8px;
}

.dark-bg{
  background: #cc4947 !important;
}
.footer-links a{
  color: #2e2b2b;
  text-decoration: none;
  font-size: 20px;
}
.link-button{
    background: rgba(255, 255, 255, 0.057) !important;
    padding: 10px 15px;
    color: #cc4947;
    text-decoration: none;
    font-size: 21px;
    border: 2px solid;
}
.link-button:hover{
    background: #cc4947 !important;
    color: #fff;
    font-size: 21px;
    transition: 1s;
}
.dark-text{
  color: #cc4947 !important;
}
.light-gry-text{
  color: #817373;
}
.dark-text span{
  border-bottom: 2px solid;
  border-radius: 4px !important;
}
.white-text a{
  color: #fff !important;
}
.title-light-white-bg{
  background: #ffffffb8;
}
.multi-img img{
  /* height: 500px; */
}
.client-bg{
  background-image: url("./img/bgSlide.jpg");
  padding: 100px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.bgslideTwo{
  background-image: url("./img/bgslide2.jpg");
  padding: 100px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.client-bg img{
  height: 100px;
  width: 100px;
}
.client-review{
  width: 67%;
  margin: auto;
  background: #ffffffd1;
  padding: 50px;
}
.text-under-link{
  margin-bottom: 0.2rem;
    font-size: 17px;
    color: #000;
}
a{
  text-decoration: none !important;
}

.navbar-toggler {
  background-color: white !important;
}
@media only screen and (max-width: 600px) {
.client-review {
  width: 100% !important ;
 }
}
.footer-img{
  background-image: url(./img/footer.jpg);
  background-size: cover;
  background-position: center;
}

.f-3{
  font-size: 3rem;
}